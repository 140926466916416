import React, {useEffect, useState} from 'react';
import {getDashboardBoxes} from '../user_accounts/Authentification';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DashboardBoxBadge from './DashboardBoxesBadge';




export default function DashboardBoxes(props) {
    
    const [dashboardBoxes, setdashboardBoxes] = useState([]);
    const [dashboardUserBoxes, setdashboardUserBoxes] = useState([]);
    
    useEffect(() => {

        getDashboardBoxes().then(data => {

            const boxes = [];

            data.docs.forEach(element => {

                const tmpBox = element.data();

                if(!!tmpBox){
                    tmpBox.id = element.id;

                    if ((Object.keys(props.userProfile).length > 0 && !props.userProfile.roles.includes('gast'))  ||  tmpBox.title === "Meetingräume" ) 
                    {
                        boxes.push(tmpBox);  
                    }
                }
                          
            });
            
            setdashboardBoxes(boxes);

        });

    }, []);


    useEffect(() => {

        if(props.userProfile.userDashboardBoxes)
        {
            const userBoxPromises = []
            const userBoxArray = [];
            props.userProfile.userDashboardBoxes.forEach(element => {
                userBoxPromises.push(element.get());
            });

            Promise.all(userBoxPromises).then((userBoxes) => {
                userBoxes.forEach(userBox => {
                    const tmpBox = userBox.data();
                    if(!!tmpBox){
                        tmpBox.id = userBox.id;
                        userBoxArray.push(tmpBox);
                    }
                });
                setdashboardUserBoxes(userBoxArray);
            });

        }

    }, [props.userProfile]);


    const allDashboardBoxes = dashboardBoxes.concat(dashboardUserBoxes);

    const renderedDashboardBoxes = allDashboardBoxes.map((el) => {

        if(el.external)
        {
            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={el.title} >
                    <a href={el.link} target="_blank" rel='noopener noreferrer' style={{backgroundColor: el.color}} className="dashboard-box">
                        <DashboardBoxBadge id={el.id} userProfileRef={props.userProfileRef} />
                        <div>
                            <h2>{el.title}</h2>
                            <Icon children={el.icon} />
                        </div>
                    </a>
                </Grid>
            )
        }
        else
        {
            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={el.title} >
                    <Link to={el.link} style={{backgroundColor: el.color}} className="dashboard-box">
                        <DashboardBoxBadge id={el.id} userProfileRef={props.userProfileRef} />
                        <div>
                            <h2>{el.title}</h2>
                            <Icon children={el.icon} />
                        </div>
                    </Link>
                </Grid>
            )
        }
        
    });

    return (
        <>
            {renderedDashboardBoxes}
        </>
    )
}
